import '../../styling/layout/LoadingProxy.css';


export const BoblerListsLoadingProxy = () => {
    const rankItems = [];
    const lists = [];

    for (let i = 0; i < 5; i++) {
        rankItems.push(<div className={"loading_proxy__list__ranking"}>
            <div className={"loading_proxy__list__ranking__item"}>
                <div className={"loading_proxy__list__ranking__item__number"}/>
                <div className={"loading_proxy__list__ranking__item__content"}>
                    <div className={"loading_proxy__list__ranking__item__content__title"}/>
                    <div className={"loading_proxy__list__ranking__item__content__description"}/>
                    <div className={"loading_proxy__list__ranking__item__content__url"}/>
                </div>
            </div>
        </div>);
    }

    for (let i = 0; i < 10; i++) {
        lists.push(
            <div className={"loading_proxy__list"}>
                <div className={"loading_proxy__list__title"}/>
                {rankItems}
                <div className={"loading_proxy__list__hashtags"}>
                    <div/>
                    <div/>
                </div>
            </div>);
    }

    return (
        <div className={"loading_proxy"}>
            {lists}
        </div>
    );
}