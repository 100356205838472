import React from "react";
import '../../styling/layout/NavBar.css';
import logo from "../../icons/logo.svg";
import logoFont from "../../icons/logo_font.svg";
import Button, {ButtonType} from "../../components/button/Button";


const NavBar = () => {

    return (
        <header className={"navbar"}>
            <img className={"navbar__logo"} src={logo}></img>
            <img className={"navbar__logo__font"} src={logoFont}></img>
            <Button type={ButtonType.secondary} iconId={"burgerIcon"}onClick={() => console.log("BurgerMenu")} />
        </header>
    );
}

export default NavBar;