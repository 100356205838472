import React, {useContext} from "react";
import {ShareContext} from "./ShareContext";
import {Paths, UrlContext} from "../UrlContext";
import {deleteImage, emptyState, validateModel} from "./PostBusinessLogic";
import axios from "axios";
import {env} from "../../config";
import Button, {ButtonType} from "../../components/button/Button";

export const PostButtons = () => {
    const shareContext = useContext(ShareContext);
    const urlContext = useContext(UrlContext);

    const clearState = () => {
        deleteImage(shareContext?.list.imageId)
        shareContext.setList(emptyState);
        shareContext.setCheckMode(false);
    }

    const postList = () => {
        console.log(JSON.stringify(shareContext.list));

        axios.post(env.apiUrl + "/api/v1/list/", shareContext.list).catch((error) => {
            shareContext.setCheckMode(true);
        }).then((response) => {
            if (response?.status === 200) {
                urlContext.setPath(Paths.FEED)
            } else if (response?.status === 400) {
                console.log("Enter Check Mode");
                shareContext.setCheckMode(true);
            }
        });
    }

    return (
        <div className={"post__buttons"}>
            <Button onClick={() => clearState()} type={ButtonType.secondary} iconId={"cancelIcon"}/>
            <Button onClick={() => postList()} type={ButtonType.primary} label={"share now"}
                    isEnabled={!shareContext.isCheckMode || validateModel(shareContext.list)}/>
        </div>
    );
}