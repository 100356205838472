import React, {FC, ReactElement} from "react";
import "../../styling/components/Button.css"

export enum ButtonType {
    action,
    primary,
    secondary,
}

type Props = {
    type: ButtonType,
    iconId?: string,
    label?: string,
    onClick: () => void,
    isEnabled?: boolean,
}

const Button: FC<Props> = (props) => {
    let content: Array<ReactElement> = new Array();
    let key = 0;

    if (props.iconId) {
        content.push(<svg key={key} className={"button__icon"} viewBox={"0 0 16.34 16.34"}>
            <use href={"#" + props.iconId}></use>
        </svg>);
        key++;
    }
    if (props.label) {
        content.push(<p key={key} className={"button__label"}
                        style={{marginLeft: props.iconId ? '0.5em' : '1.5em'}}>{props.label}</p>);
    }

    return (
        <button
            className={"button " + ButtonType[props.type] + "Button"}
            onClick={() => {
                if (props.isEnabled !== false)
                    props.onClick();
            }}
            disabled={props.isEnabled === false}>
            {
                content
            }
        </button>
    );
}

export default Button;