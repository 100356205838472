import Button, {ButtonType} from "../../components/button/Button";
import {env} from "../../config";
import DropZone from "./DropZone";
import React, {useContext} from "react";
import {ShareContext} from "./ShareContext";
import {deleteImage} from "./PostBusinessLogic";
import PostEntry from "./PostEntry";


export const PostList = () => {
    const shareContext = useContext(ShareContext);

    const clearImage = () => {
        deleteImage(shareContext.list.imageId);
        const newState = {
            ...shareContext.list,
            imageId: undefined
        }

        shareContext.setList(newState);
    }

    const uploadPicture = (pImageId: string) => {
        const newState = {
            ...shareContext.list,
            imageId: pImageId
        }

        shareContext.setList(newState);
    }

    const items = [];
    for (let i: number = 1; i <= 5; i++) {
        let stateItem = shareContext.list.items[i - 1];
        items.push(<PostEntry key={i - 1} rankNumber={i}
                              title={stateItem.title}
                              description={stateItem.description}
                              url={stateItem.url}/>);
    }

    return (
        <div className={"post__list"}>
            {
                shareContext.list.imageId
                    ? <div className={"imageContainer"}>
                        <div className={"deleteImageButton"}>
                            <Button onClick={() => clearImage()} type={ButtonType.secondary}
                                    iconId={"cancelIcon"}/>
                        </div>
                        <img className={"listImage"}
                             src={env.apiUrl + '/api/v1/media/' + shareContext.list.imageId}/>
                    </div>
                    : <DropZone onPictureSelected={uploadPicture}/>
            }
            {items}
        </div>
    );
}
