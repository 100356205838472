import React, {ReactElement} from "react";
import '../styling/components/TabBar.css';
import Link from "../util/routing/Link";
import {Paths} from "../app/UrlContext";

export type TabBarConfig = {
    tabConfigurations: {
        text: string,
        iconId?: string,
        path: Paths
    }[],
    className?: string
};


const TabBar: React.FC<TabBarConfig> = (props) => {
    let content: Array<ReactElement> = [];


    for (let i: number = 0; i < props.tabConfigurations.length; i++) {
        const config = props.tabConfigurations[i];

        content.push(
            <Link key={i}
                  className={"tabbar__tab"}
                  activeClassName={"tabbar__tab__active"}
                  path={config.path}
            >
                <svg className={"tabbar__tab__icon icon"} viewBox={"0 0 16.34 16.34"}>
                    <use href={"#" + config.iconId}/>
                </svg>
                {config.text}
            </Link>
        );
    }

    return (
        <ul className={`tabbar__tabs ${props.className}`}>
            {
                content
            }
        </ul>
    );
}

export default TabBar;