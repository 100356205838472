import React, {FC, useContext} from "react";
import Input, {FontWeight} from "../../components/textfield/Input";
import {ListItem} from "./Types";
import {ShareContext} from "./ShareContext";

interface PostEntryProps extends ListItem {
    rankNumber: number,
}

const PostEntry: FC<PostEntryProps> = (props) => {

    const shareContext = useContext(ShareContext)

    const onItemChanged = (rankNumber: number, prop: string, val: string) => {
        const rankIndex = rankNumber - 1;

        let tempList = {...shareContext.list};
        let ranking = [...tempList.items];
        ranking[rankIndex] = {
            ...ranking[rankIndex],
            [prop]: val
        };

        shareContext.onPropChanged('items', ranking);
    }

    const changeValue = (prop: string, val: string) => {
        onItemChanged(props.rankNumber, prop, val);
    }

    return (
        <div className={"post__item"}>
            <div className={"rankNumber"}>{props.rankNumber}</div>
            <div className={"post__item__details"}>
                <Input label={"Title"}
                       fontWeight={FontWeight.bold}
                       onInputChanged={changeValue}
                       value={props.title}
                       isRequired={true}
                       isStrict={shareContext.isCheckMode}/>
                <Input label={"Description"} maxRowNumber={4} onInputChanged={changeValue}
                       value={props.description}/>
                <Input label={"URL"} onInputChanged={changeValue} value={props.url}/>
            </div>
        </div>
    );
}

export default PostEntry;