import axios from "axios";
import {env} from "../config";
import {ViewList} from "../app/lists/Types";


export const wrapPromise = <T>(promise : Promise<any>) => {
    console.log("Too often")

    let status = "pending";
    let result : T;
    let suspender = promise.then(
        (response) => {
            status = "success";
            result = response;
        },
        (error) => {
            status = "error";
            result = error;
        }
    );

    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            } else if (status === "success") {
                return result;
            }
        }
    };
}


const LIST_ENDPOINT = "/api/v1/list";

export const readAllLists = () => {
    console.log("Fetching all lists ...");
    return axios.get(env.apiUrl + LIST_ENDPOINT)
        .then(response => sortLists(response.data))
        .catch(error => console.log(error));
}

export const filterLists = (hashtags : string[]) => {
    console.log("Fetching filtered lists ...");
    return axios.get(env.apiUrl + LIST_ENDPOINT + "/search?hashtags=" + hashtags.join(","))
        .then(response => sortLists(response.data))
        .catch(error => console.log(error));
}

const sortLists = (lists: ViewList[]) => {
    return lists.sort((a: ViewList, b: ViewList) => a.postedAt > b.postedAt ? -1 : 1);
}