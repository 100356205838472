import {createContext} from 'react'

interface ContextType {
    hashtags: string[],
    setHashtags: (list: string[]) => void
}

export const SearchContext = createContext<ContextType>({} as ContextType);

export const LocalSearchContext = createContext<ContextType>({} as ContextType);
