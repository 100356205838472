import Button, {ButtonType} from "../../components/button/Button";
import {HashtagSearchInput} from "../../components/textfield/HashtagInput";
import "../../styling/layout/SearchFilter.css"
import {useContext, useState} from "react";
import {LocalSearchContext, SearchContext} from "./SearchContext";
import {MAX_HASHTAG_COUNT} from "../share/PostConstants";
import {isHashtagValid} from "../validation/ValidationBusinessLogic";


export const SearchFilter = () => {
    const [isShowing, setShowing] = useState<boolean>(false);
    const searchContext = useContext(SearchContext);

    const [hashtags, setHashtags] = useState<string[]>(searchContext.hashtags);

    const hashtagInputs = [];
    for (let i = 0; i < MAX_HASHTAG_COUNT; i++) {
        hashtagInputs.push(<HashtagSearchInput order={i} key={i}/>);
    }

    return (
        <LocalSearchContext.Provider value={{hashtags, setHashtags}}>
            <div className={`search__filter ${isShowing ? "showing" : ""}`}>
                <div className={"search__filter__navigation"}>
                    <p className={`search__filter__label ${!isShowing ? "showing" : ""}`}>filter</p>
                    <Button type={ButtonType.secondary}
                            iconId={isShowing ? "cancelIcon" : "searchIcon"}
                            onClick={() => setShowing(!isShowing)}/>
                </div>
                <div className={`search__filter__container ${isShowing ? "showing" : ""}`}>
                    <div className={"search__filter__container__criteria"}>
                        {hashtagInputs}
                    </div>
                    <Button type={ButtonType.action}
                            iconId={"searchIcon"}
                            isEnabled={searchContext.hashtags.every(isHashtagValid)}
                            onClick={() => {
                                searchContext.setHashtags(hashtags);
                                setShowing(false);
                            }}
                    />
                </div>
            </div>
        </LocalSearchContext.Provider>
    )
}