import React, {useState} from "react";
import '../../styling/layout/Share.css';
import Input, {FontWeight} from "../../components/textfield/Input";
import {List, ListItem} from "./Types";
import {ShareContext} from "./ShareContext";
import {emptyState } from "./PostBusinessLogic";
import {PostButtons} from "./PostButtons";
import {PostList} from "./PostList"
import {PostHashtags} from "./PostHashtags";



const Post = () => {
    const [list, setList] = useState<List>(emptyState);
    const [isCheckMode, setCheckMode] = useState<boolean>(false);

    const onPropChanged = (prop: string, val: string | ListItem[] | string[]) => {
        const newState = {
            ...list,
            [prop]: val
        }
        setList(newState);
    }

    return (
        <ShareContext.Provider value={{list, setList, onPropChanged, isCheckMode, setCheckMode}}>
            <div className={"post__area"}>
                <div className={"post__container"}>
                    <div className={"banner"}>
                        <span className={"profilePic"}>TR</span>
                        <div className={"postInfo"}>
                            <h2 className={"staticHeading"}>my favourite five of...</h2>
                            <Input label={"Title"}
                                   fontWeight={FontWeight.bold}
                                   onInputChanged={onPropChanged}
                                   value={list.title}
                                   isRequired={true}
                                   isStrict={isCheckMode}/>
                        </div>
                    </div>
                    <PostList/>
                    <PostHashtags/>
                </div>
                <PostButtons/>
            </div>
        </ShareContext.Provider>
    );
}

export default Post;