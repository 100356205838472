import Input from "./Input";
import {ShareContext} from "../../app/share/ShareContext";
import {useContext} from "react";
import {MIN_HASHTAG_COUNT} from "../../app/share/PostConstants";
import {isHashtagValid} from "../../app/validation/ValidationBusinessLogic";
import {LocalSearchContext} from "../../app/search/SearchContext";

type Props = {
    order: number
}

export const HashtagInput = (props: Props) => {
    const shareContext = useContext(ShareContext);

    const onItemChanged = (prop: string, tag: string) => {
        let newHashtags = [...shareContext.list.hashtags];
        newHashtags[props.order] = tag;

        shareContext.onPropChanged('hashtags', newHashtags);
    }

    const isEverythingElseFilled = () => {
        const hashtagCount = shareContext.list.hashtags
            .filter(e => e.trim() !== '')
            .length;
        return hashtagCount >= props.order;
    }

    const hashtag = shareContext.list.hashtags[props.order];

    return (
        <div className={`hashtag hashtag__input ${!isEverythingElseFilled() ? "disabled" : ""}`}>
            #<Input label={"Hashtag"}
                    onInputChanged={onItemChanged}
                    value={hashtag}
                    isEnabled={isEverythingElseFilled()}
                    isRequired={props.order < MIN_HASHTAG_COUNT}
                    isStrict={shareContext.isCheckMode}
                    isValid={isHashtagValid(hashtag)}/>
        </div>
    );
}

export const HashtagSearchInput = (props: Props) => {
    const searchContext = useContext(LocalSearchContext);

    const onItemChanged = (prop: string, tag: string) => {
        let newHashtags = [...searchContext.hashtags];
        newHashtags[props.order] = tag;

        searchContext.setHashtags(newHashtags);
    }

    const hashtag = searchContext.hashtags[props.order];

    return (
        <div className={"hashtag hashtag__input"}>
            #<Input label={"Hashtag"}
                    onInputChanged={onItemChanged}
                    value={hashtag}
                    isValid={isHashtagValid(hashtag)}/>
        </div>
    );
}