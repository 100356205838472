import React, {useEffect, useState} from 'react';
import '../styling/layout/App.css';
import Post from "./share/Share";
import NavBar from "./navbar/NavBar";
import Route from "../util/routing/Route";
import TabBar from "../components/TabBar";
import share from "../icons/share_deco.svg"
import feed from "../icons/feed_deco.svg"
import search from "../icons/search_deco.svg"
import DecoComp from "../components/DecoComp";
import {Paths, setWindowPath, UrlContext} from './UrlContext';
import {Search} from "./search/Search";
import {Feed} from "./feed/Feed";
import {MAX_HASHTAG_COUNT} from "./share/PostConstants";
import { SearchContext } from './search/SearchContext';


function App() {
    const [path, setPath] = useState(Paths.FEED);
    const [hashtags, setHashtags] = useState<string[]>(new Array<string>(MAX_HASHTAG_COUNT).fill(""));


    useEffect(() => {
        setWindowPath(path);
    }, [path]);

    return (
        <UrlContext.Provider value={{path, setPath}}>
            <div className="app">
                <NavBar/>
                <TabBar className={"nav__tabs"} tabConfigurations={[
                    {
                        text: "feed",
                        iconId: "feedIcon",
                        path: Paths.FEED
                    },
                    {
                        text: "search",
                        iconId: "searchIcon",
                        path: Paths.SEARCH
                    },
                    {
                        text: "share",
                        iconId: "shareIcon",
                        path: Paths.SHARE
                    }
                ]}/>
                <SearchContext.Provider value={{hashtags, setHashtags}}>
                    <div className={"app__stage"}>
                        <Route path={Paths.FEED} className={"app__container"}>
                            <div></div>
                            <Feed/>
                            <DecoComp imgSrc={feed} header={"feed"} text={"my inspiration"}/>
                        </Route>
                        <Route path={Paths.SHARE} className={"app__container"}>
                            <div></div>
                            <Post/>
                            <DecoComp imgSrc={share} header={"share"} text={"my knowledge"}/>
                        </Route>
                        <Route path={Paths.SEARCH} className={"app__container"}>
                            <div></div>
                            <Search/>
                            <DecoComp imgSrc={search} header={"search"} text={"for favorites"}/>
                        </Route>
                    </div>
                </SearchContext.Provider>
            </div>
        </UrlContext.Provider>
    );
}

export default App;
