const {
    API_URL,
} = (window as any).env

export const env: Environment = {
    apiUrl: API_URL,
}

type Environment = {
    apiUrl: string
}