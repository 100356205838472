import {ViewListItem} from "./Types";

export const ListItemComponent = (viewItem: ViewListItem) => {
    let item = viewItem.listItem;
    return (
        <div className={"listItem"}>
            <span className={"rankNumber"}>{viewItem.index + 1}</span>
            <div className={"itemDetails"}>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
                {
                    item.url ?
                        <a href={item.url} target={"_blank"}>Link</a>
                        : null
                }
            </div>
        </div>
    );
}