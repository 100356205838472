import React from "react";
import '../../styling/components/Hashtag.css';


export const HashtagButton = (props: { tag: String }) => {
    const onClick = () => {
        console.log("clicked " + props.tag)
    }

    return (
        <div className={"hashtag hashtag__button"}
             onClick={() => onClick()}>#{props.tag}</div>
    );
}