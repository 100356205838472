import React, {useContext} from 'react';
import {UrlContext} from "../../app/UrlContext";


type Props = {
    path: string,
    className?: string,
    children: JSX.Element[] | JSX.Element,
}

const Route = ({
                   path,
                   className,
                   children,
               }: Props) => {
    const urlContext = useContext(UrlContext);
    return urlContext?.path === path
        ? <div className={`${className ? className : ""}`}>
            {children}
        </div>
        : null;
}

export default Route;