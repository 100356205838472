import React from 'react';
import ReactDOM from 'react-dom/client';
import './styling/index.css';
import App from './app/App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>
        <svg className={"iconContainer"} xmlns={"http://www.w3.org/2000/svg"} viewBox={"0 0 16.34 16.34"}>
            <symbol id={"cancelIcon"}>
                <path
                      d={"M9.58,8.17l2.21-2.21c.15-.15,.15-.38,0-.53l-.89-.89c-.15-.15-.38-.15-.53,0l-2.21,2.21-2.21-2.21c-.15-.15-.38-.15-.53,0l-.89,.89c-.15,.15-.15,.38,0,.53l2.21,2.21-2.21,2.21c-.15,.15-.15,.38,0,.53l.89,.89c.15,.15,.38,.15,.53,0l2.21-2.21,2.21,2.21c.15,.15,.38,.15,.53,0l.89-.89c.15-.15,.15-.38,0-.53l-2.21-2.21Z"}></path>
            </symbol>
        </svg>
        <svg className={"iconContainer"} xmlns={"http://www.w3.org/2000/svg"} viewBox={"0 0 16.34 16.34"}>
            <symbol id={"feedIcon"}>
                <path
                      d={"M12.21,7.73l-5.82-3.36c-.33-.19-.75,.05-.75,.43v6.72c0,.39,.42,.63,.75,.43l5.82-3.36c.33-.19,.33-.68,0-.87Z"}></path>
            </symbol>
        </svg>

        <svg className={"iconContainer"} xmlns={"http://www.w3.org/2000/svg"} viewBox={"0 0 16.34 16.34"}>
            <symbol id={"shareIcon"}>
                <path
                      d={"M12.29,7.17h-3.13v-3.13c0-.21-.17-.37-.37-.37h-1.25c-.21,0-.37,.17-.37,.37v3.13h-3.13c-.21,0-.37,.17-.37,.37v1.25c0,.21,.17,.37,.37,.37h3.13v3.13c0,.21,.17,.37,.37,.37h1.25c.21,0,.37-.17,.37-.37v-3.13h3.13c.21,0,.37-.17,.37-.37v-1.25c0-.21-.17-.37-.37-.37Z"}></path>
            </symbol>
        </svg>

        <svg className={"iconContainer"} xmlns={"http://www.w3.org/2000/svg"} viewBox={"0 0 16.34 16.34"}>
            <symbol id={"searchIcon"}>
                <path
                      d={"M9.01,3.04c-2.3,0-4.17,1.87-4.17,4.17,0,.79,.23,1.52,.61,2.14l-2.15,2.15c-.15,.15-.15,.38,0,.53l.89,.89c.15,.15,.38,.15,.53,0l2.15-2.15c.63,.38,1.36,.61,2.14,.61,2.3,0,4.17-1.87,4.17-4.17s-1.87-4.17-4.17-4.17Zm0,6.64c-1.36,0-2.47-1.11-2.47-2.47s1.11-2.47,2.47-2.47,2.47,1.11,2.47,2.47-1.11,2.47-2.47,2.47Z"}></path>
            </symbol>
        </svg>
        <svg className={"iconContainer"} xmlns={"http://www.w3.org/2000/svg"} viewBox={"0 0 16.34 16.34"}>
            <symbol id={"burgerIcon"}>
                <rect className="cls-1" x="7.17" y="7.12" width="2" height="8.5" rx=".37" ry=".37"
                      transform="translate(19.54 3.2) rotate(90)"></rect>
                <rect className="cls-1" x="7.17" y="3.92" width="2" height="8.5" rx=".37" ry=".37"
                      transform="translate(16.34 0) rotate(90)"></rect>
                <rect className="cls-1" x="7.17" y=".71" width="2" height="8.5" rx=".37" ry=".37"
                      transform="translate(13.13 -3.2) rotate(90)"></rect>
            </symbol>
        </svg>
    </React.StrictMode>
);

