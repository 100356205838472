import axios from "axios";
import {env} from "../../config";
import {List} from "./Types";
import {MAX_HASHTAG_COUNT, MIN_HASHTAG_COUNT} from "./PostConstants";
import {hashtagRegex} from "../validation/ValidationBusinessLogic";

export const deleteImage = (imageId: string | undefined) => {
    if (imageId) {
        axios.delete(
            env.apiUrl + "/api/v1/media/" + imageId);
    }
}

export const validateModel = (list: List) => {
    if (!list.title || list.title.length === 0) {
        return false;
    }

    for (var i of list.items) {
        if (!i.title || i.title.length === 0) {
            return false;
        }
    }

    let hashtags = list.hashtags.filter(tag => tag.trim().length > 0);
    let hashtagCount = hashtags.length;

    if (hashtagCount < MIN_HASHTAG_COUNT || hashtagCount > MAX_HASHTAG_COUNT
        || hashtags.filter(tag => !hashtagRegex.test(tag)).length !== 0) {
        return false;
    }

    return true;
}

export const emptyState = {
    title: '',
    items: [
        {
            title: '',
            description: '',
            url: '',
        },
        {
            title: '',
            description: '',
            url: '',
        },
        {
            title: '',
            description: '',
            url: '',
        },
        {
            title: '',
            description: '',
            url: '',
        },
        {
            title: '',
            description: '',
            url: '',
        }
    ],
    hashtags: ['', ''],
};