import React, {MouseEvent, useContext} from 'react';
import '../../styling/components/TabBar.css';
import {Paths, UrlContext} from "../../app/UrlContext";

type Props = {
    path: Paths,
    className: string,
    activeClassName: string,
    children: React.ReactNode
}

const Link = (props: Props) => {

   const urlContext = useContext(UrlContext)

    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        urlContext.setPath(props.path);
    }

    return (
        <a href={props.path.valueOf()}
           className={`${props.className} ${urlContext?.path === props.path
               ? props.activeClassName
               : ""}`
           }
           onClick={handleClick}>
            {props.children}
        </a>
    );
}

export default Link;