import React, {FC, useCallback} from "react";
import axios from "axios";
import {useDropzone} from "react-dropzone";
import {env} from "../../config";

interface DropZoneProps {
    onPictureSelected: (a: string) => void
}

const DropZone: FC<DropZoneProps> = (props) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file.type.startsWith("image")) {
            const formData = new FormData();
            formData.append("image", file);

            axios({
                method: "post",
                url: env.apiUrl + "/api/v1/media",
                data: formData,
                headers: {'Content-Type': 'multipart/form-data'}
            }).then((response) => {
                const imageId: string = response.data;

                props.onPictureSelected(imageId);
            }).catch((err) => console.log(err));
        }
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div className={"DropZone"} {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the image here ...</p> :
                    <p>Drag 'n' drop an image here, or click to select one ...</p>
            }
        </div>
    )
}

export default DropZone;