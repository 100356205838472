import {SearchFilter} from "./SearchFilter";
import {useContext, useEffect, useState} from "react";
import {isHashtagValid} from "../validation/ValidationBusinessLogic";
import {BoblerLists} from "../lists/BoblerListsComp";
import {filterLists, readAllLists, wrapPromise} from "../../util/Api";
import {ViewList} from "../lists/Types";
import {SearchContext} from "./SearchContext";

export const Search = () => {

    const [searchApiCall, setSearchApiCall] = useState(wrapPromise<ViewList[]>(readAllLists()));
    const searchContext = useContext(SearchContext);

    const triggerSearch = (hashtags: string[]) => {
        console.log("Search triggered");
        const hashtagList = hashtags
            .filter(tag => tag.trim().length > 0 && isHashtagValid(tag));

        if (hashtagList.length === 0) {
            setSearchApiCall(wrapPromise<ViewList[]>(readAllLists()));

        } else {
            setSearchApiCall(wrapPromise<ViewList[]>(filterLists(hashtagList)));
        }
    }

    useEffect(() => triggerSearch(searchContext.hashtags), [searchContext.hashtags]);

    console.log("Rendering Search");
    return (
        <div>
            <SearchFilter/>
            <BoblerLists resource={searchApiCall}/>
        </div>
    );

}
