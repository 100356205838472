import React from "react";
import '../../styling/layout/Feed.css';
import {BoblerLists} from "../lists/BoblerListsComp";
import {ViewList} from "../lists/Types";
import {readAllLists, wrapPromise} from "../../util/Api";

export const Feed = () => {
    return (
        <BoblerLists resource={wrapPromise<ViewList[]>(readAllLists())}/>
    );
}
