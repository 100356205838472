import React, {Suspense} from "react";
import {ViewList} from "./Types";
import {ListComponent} from "./BoblerListComp";
import {BoblerListsLoadingProxy} from "./BoblerListsLoadingProxy";

type BoblerListProps = {
    resource: WrappedApiCall<ViewList[]>
}

interface WrappedApiCall<T> {
    read: () => T | undefined
}

export const BoblerLists = (props: BoblerListProps) => {
    return (
        <Suspense fallback={<BoblerListsLoadingProxy/>}>
            <SuspendedBoblerLists resource={props.resource}/>
        </Suspense>
    );
}

export const SuspendedBoblerLists = (props: BoblerListProps) => {
    const lists = props.resource.read();
    if (!lists || lists.length === 0) {
        return (
            <div className={"info"}>
                <h1>No Results</h1>
                <p>Sorry we don't have anything for you yet.</p>
            </div>
        );
    }
    return (
        <div className={"feed__area"}>
            <div className={"feed__container"}>
                {
                    lists.map((list, index) => {
                        return (
                            <ListComponent listId={list.listId} title={list.title} imageId={list.imageId}
                                           postedAt={list.postedAt}
                                           ranking={list.ranking}
                                           hashtags={list.hashtags} key={index}/>
                        );
                    })
                }
            </div>
        </div>
    );
}
