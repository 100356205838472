import {createContext} from 'react'
import {List, ListItem} from "./Types";

interface ContextType {
    list: List,
    setList: (list: List) => void,
    onPropChanged: (prop: string, val: string | ListItem[] | string[]) => void,
    isCheckMode: boolean,
    setCheckMode: (checkState: boolean) => void
}

export const ShareContext = createContext<ContextType>({} as ContextType);