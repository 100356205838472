import React, {FC, useEffect, useRef} from "react";
import "../../styling/components/Input.css"

export enum FontWeight {
    light,
    normal,
    bold
}

type Props = {
    isRequired?: boolean,
    isStrict?: boolean,
    label: string,
    value?: string,
    fontWeight?: FontWeight,
    maxRowNumber?: number,
    onInputChanged: (prop: string, val: string) => void,
    isEnabled?: boolean,
    isValid?: boolean;
}

const Input: FC<Props> = (props) => {
    const lineHeight = 18;
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (textareaRef && textareaRef.current) {
            textareaRef.current.style.height = "inherit";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = Math.min(scrollHeight, (props.maxRowNumber || 1) * lineHeight) + "px";
        }
    }, [props.value]);

    const isEmpty = !props.value || props.value.trim().length === 0;
    let labelText: string;

    if (props.isStrict && props.isRequired && isEmpty) {
        labelText = 'Please insert ' + props.label;
    } else {
        labelText = props.label;
        if (!props.isRequired) {
            labelText += ' (optional)';
        }
    }

    const disabled = props.isEnabled !== undefined && !props.isEnabled;

    return (
        <div className={"input"} style={props.isValid !== undefined && !props.isValid ?{color: "red"}: {}}>
            {
                props.maxRowNumber
                    ? <textarea
                        className={"input__input " + FontWeight[props.fontWeight ? props.fontWeight : FontWeight.normal] + "Input"}
                        value={props.value}
                        rows={1}
                        required={true}
                        disabled={disabled}
                        ref={textareaRef}
                        onChange={(e) => props.onInputChanged(props.label.toLocaleLowerCase(), e.target.value)}
                    />
                    : <input
                        value={props.value}
                        className={"input__input " + FontWeight[props.fontWeight ? props.fontWeight : FontWeight.normal] + "Input"}
                        type={"text"}
                        placeholder={""}
                        required={true}
                        disabled={disabled}
                        onChange={(e) => props.onInputChanged(props.label.toLocaleLowerCase(), e.target.value)}
                    />
            }
            <label className={"input__label"}
                // style={{borderColor: props.isStrict && props.isRequired && isEmpty ? '#ff5131' : 'var(--dimmed-color)'}}
            >
                <div className={"input__label__div"}>
                    <span className={"input__label__span"}
                          style={{color: props.isStrict && props.isRequired && isEmpty ? 'red' : 'var(--dimmed-color)'}}
                    >{labelText}</span>
                </div>
            </label>
        </div>
    );
}

export default Input;