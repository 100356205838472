import {createContext} from 'react'

export enum Paths {
    "FEED" = "/feed",
    "SHARE" = "/share",
    "SEARCH" = "/search",
}

interface ContextType {
    path: Paths,
    setPath: (path: Paths) => void
}

export const setWindowPath = (path: Paths) => {
    window.history.pushState({}, "", path.valueOf());

    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
}

export const UrlContext = createContext<ContextType>({} as ContextType);