import {HashtagInput} from "../../components/textfield/HashtagInput";
import {MAX_HASHTAG_COUNT} from "./PostConstants";

export const PostHashtags = () => {
    const hashtags = [];
    for(let i = 0; i < MAX_HASHTAG_COUNT; i++) {
        hashtags.push(<HashtagInput key={i} order={i}/>)
    }

    return(
        <div className={"post__container__hashtags"}>
            {hashtags}
        </div>
    );
}
