import DateLabelProvider from "../../util/LabelProvider";
import {env} from "../../config";
import {HashtagButton} from "../../components/button/HashtagButton";
import React from "react";
import {ListItemComponent} from "./ListItemComp";
import {ViewList} from "./Types";

export const ListComponent = (list: ViewList) => {
    return (

        <div className={"list"}>
            <div className={"banner"}>
                <span className={"profilePic"}>TR</span>
                <div className={"postInfo"}>
                    <h3 className={"staticHeading"}>my favourite five of ...</h3>
                    <h3>{list.title}</h3>
                    <p className={"banner__date"}>{DateLabelProvider(list.postedAt)}</p>
                </div>
            </div>
            {
                list.imageId ?
                    <img className={"listImage"} src={env.apiUrl + '/api/v1/media/' + list.imageId}/>
                    : null
            }
            <div>
                {
                    list.ranking.map((rank, index) => {
                        return (
                            <ListItemComponent listItem={rank} index={index} key={index}/>
                        );
                    })
                }
            </div>
            {
                list.hashtags && list.hashtags.length !== 0 ?
                    <div className={"feed__list__metadata"}>
                        <div className={"feed__list__metadata__hashtags"}>
                            {
                                list.hashtags.map((hashtag, index) => {
                                        return hashtag.trim().length > 0
                                            ? (
                                                <HashtagButton key={index} tag={hashtag}/>
                                            )
                                            : null;
                                    }
                                )
                            }
                        </div>
                    </div>
                    : null
            }

        </div>
    );
}