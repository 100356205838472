import React from "react";
import '../styling/components/DecoComp.css';

export type Props = {
    imgSrc: string,
    header: string,
    text: string
};


const DecoComp = (props: Props) => {

    return (
        <div className={"deco"}>
            <img className={"deco__icon"} src={props.imgSrc}/>
            <div className={"deco__text"}>
                <h3>{props.header}</h3>
                <p>{props.text}</p>
            </div>
        </div>
    );
}

export default DecoComp;